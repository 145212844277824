<template>
  <div class="container">
    <nav-box :title="title" :back="true" :callback="goBack"></nav-box>
    <div class="content">
      <div class="code flex">
        <input
          ref="input"
          v-model="couponCode"
          autofocus
          @keydown.enter="affirm"
        />
      </div>
      <div class="keyboard flex">
        <div
          v-for="item in 9"
          :key="item"
          class="number flex flex-center"
          @click="writeIn(item)"
        >
          {{ item }}
        </div>
        <div class="number flex flex-center clear" @click="clear">清除</div>
        <div class="number flex flex-center" @click="writeIn(0)">0</div>
        <div class="number flex flex-center affirm" @click="affirm">确认</div>
      </div>
      <div class="tips flex">
        <div class="warning flex flex-center">
          <van-icon name="warning-o" />
        </div>

        <p>请扫描订单二维码或输入核销码</p>
      </div>
    </div>
  </div>
</template>

<script>
import { swallowKeyNo,spitoutKeyNo } from "@/utils/recycler";
import navBox from "@/components/nav-box.vue";
import { Toast } from "vant";
import axios from "axios";
export default {
  components: {
    navBox,
  },
  name: "verification",
  data() {
    return {
      title: "抖音核销",
      couponCode: "",
      thirdType: 0,
      details: {},
      departure:false,
      isRecycler:false,// 自助机程序是否启用手牌回收箱
      targetMemberCardId:null,//返现会员ID
      targetMemberCardNo:null,//返现会员卡号
    };
  },
  created() {
    this.thirdType = this.$route.query.thirdType;
    this.departure = this.$route.query.departure;
    this.targetMemberCardId = this.$route.query.targetMemberCardId;
    this.targetMemberCardNo = this.$route.query.targetMemberCardNo;
    
    this.title =
      this.thirdType == 4
        ? "抖音核销"
        : this.thirdType == 1
        ? "美团核销"
        : this.thirdType == 2
        ? "口碑核销"
        : "券核销";
  },
  mounted() {
    this.$refs.input.focus();
    // 自助机程序是否启用手牌回收箱
    this.getRecycler()
  },
  methods: {
    // 写入券号
    writeIn(item) {
      this.couponCode = this.couponCode + item;
      this.$refs.input.focus();
    },
    // 清除券号
    clear() {
      this.couponCode = "";
      this.$refs.input.focus();
    },
    // 确认核销
    async affirm() {
      if (this.couponCode == "") {
        this.$toast("请输入或扫描券号");
        return false;
      }
      // 套餐消费第三方类型(1: 美团, 2: 口碑, 4: 抖音，8: 会员优惠券)
      // 判断  抖音券号为15位   美团为12位   扫码抖音包含douyin字段    会员券为 000开头并且是12位
      if (this.couponCode.includes("douyin") || this.couponCode.length == 15) {
        this.thirdType = 4;
      } else if(/^000\d{9}$/.test(this.couponCode)){
        this.thirdType = 8;
      }else if(this.couponCode.includes("wx-")){
        // 微信商城优惠券
        this.thirdType = 16;
        this.couponCode = this.couponCode .replace(/wx-/, "")
      } else {
        this.thirdType = 1;
      }
      this.$fetch(this.Api.ticket, {
        couponCode: this.couponCode,
        consumptionAreaId:localStorage.getItem("consumptionAreaId"),
        thirdType: this.thirdType,
      }).then((res) => {
        this.$router.push({
          path: "/verificationList",
          query: {
            couponCode: this.couponCode,
            thirdType: this.thirdType,
            departure: this.departure,
            targetMemberCardId:this.targetMemberCardId,
            targetMemberCardNo:this.targetMemberCardNo,
          },
        });
      });
    },
    // 调单
    getOrder() {
      let consumptionAreaId = localStorage.getItem("consumptionAreaId");
      let consuptionSubjectIds = localStorage.getItem("consuptionSubjectIds").split(",");
      var data = {
        consumptionAreaId: consumptionAreaId,
        consuptionSubjectIds:consuptionSubjectIds,
        settlementType: 1,
        real: false,
      };
      var that = this;
      // 调单
      that.$post(that.Api.transferOrder, data).then((res) => {
        that.details = res.data;
        if (that.details.unPaidAmount === 0) {
          data.real = true;
          data.targetMemberCardId=that.targetMemberCardId
          data.targetMemberCardNo=that.targetMemberCardNo
          // 结账
          that
            .$post(that.Api.transferOrder, data)
            .then((res) => {
              // 结账成功
              if (res.code == 1) {
                
                //结账吞卡
                if(this.isRecycler){
                  swallowKeyNo(localStorage.getItem("keyCard"))
                }

                Toast.success("结账成功");
                Toast.loading({
                  message: "单据打印中，请稍后....",
                  forbidClick: true,
                  duration: 5000,
                });
                var list = that.getFlatArr(res.data.details);
                var arr = list.map((item) => {
                  return {
                    KeyNo: item.keyCardCode,
                    consumptionSubjectId:item.consumptionSubjectId
                  };
                });

                that.PrintReport(res.data.id);
                setTimeout(() => {
                  that.printShoes(res.data.needSendShoeCabinetNotifySubjectIds);
                }, 400);
                setTimeout(() => {
                  that.printDeparture(arr);
                }, 500);

                setTimeout(() => {
                  that.$router.push({
                    path: "/result",
                    query: {
                      payType: "ticket",
                      result: true,
                    },
                  });
                }, 5000);
              }
            })
            .catch((err) => {
              // 结账失败 重新调单
              Toast(err.response.data.message);
              that.$router.push({
                path: "/list",
                query: {
                  targetMemberCardId: that.targetMemberCardId,
                  targetMemberCardNo: that.targetMemberCardNo
                },
              });
            });
        } else {
          that.$router.push({
            path: "/list",
            query: {
              targetMemberCardId: that.targetMemberCardId,
              targetMemberCardNo: that.targetMemberCardNo
            },
          });
        }
      });
    },
    goBack(){
      this.$router.push({
        path: "/list",
        query: {
          targetMemberCardId: this.targetMemberCardId,
          targetMemberCardNo: this.targetMemberCardNo
        },
      });
    },
    // 重构数组
    getFlatArr(array) {
      return array.reduce((a, item) => {
        let flatArr = [...a, item];
        if (item.children) {
          flatArr = [...flatArr, ...this.getFlatArr(item.children)];
        }
        return flatArr;
      }, []);
    },
    removeBase64Prefix(base64String) {
      return base64String.replace(/^data:.*?base64,/, "");
    },
    // 搜索报表
    dataSet(body, data) {
      var that = this;
      var num = data.printCount ? data.printCount : 0;
      var invoiceAddressValue = data.invoiceAddress ? data.invoiceAddress : "";
      //创建报表对象
      var report = new window.Stimulsoft.Report.StiReport();
      // 加载文件
      report.load(body);
      //新建数据源
      var dataSet = new Stimulsoft.System.Data.DataSet("SimpleDataSet");
      //数据源填充数据
      delete data.printCount;
      delete data.invoiceAddress;
      dataSet.readJson(JSON.stringify(data));
      //给报表的数据源填充数据

      let storeName = localStorage.getItem("storeName") || "云水疗";
      report.regData(dataSet.dataSetName, storeName, dataSet);
      var variableString = report.dictionary.variables.getByName("StoreName");
      variableString.value = storeName;
      if (num) {
        var printCount = report.dictionary.variables.getByName("printCount");
        printCount.value = num;
      }
      if (invoiceAddressValue) {
        var invoiceAddress =
          report.dictionary.variables.getByName("invoiceAddress");
        invoiceAddress.value = invoiceAddressValue;
      }

      // report.regBusinessObject("StoreName", "酒店名称");
      // 创建报表
      viewer.report = report;
      report.renderAsync(async function () {
        if (typeof Android2JS != "undefined") {
          // 当报告渲染完成后导出为PNG图片
          report.exportDocumentAsync(function (htmlString) {
            // Write HTML text to DIV element.
            var container = document.getElementById("htmlContainer");
            container.innerHTML = htmlString;
            html2canvas(document.getElementById("htmlContainer")).then(
              (canvas) => {
                // 创建一个新的canvas来调整图像尺寸
                var ctx = canvas.getContext("2d");
                var canvasCopy = document.createElement("canvas");
                var copyContext = canvasCopy.getContext("2d");

                // 设置新canvas的尺寸
                canvasCopy.width = 576;
                canvasCopy.height = (canvas.height * 576) / canvas.width;

                // 使用drawImage方法按比例缩放原始canvas内容到新的canvas
                copyContext.drawImage(
                  canvas,
                  0,
                  0,
                  canvas.width,
                  canvas.height,
                  0,
                  0,
                  canvasCopy.width,
                  canvasCopy.height
                );
                var imgData = that.removeBase64Prefix(
                  canvasCopy.toDataURL("image/png")
                );

                Android2JS.Print3Lines();
                Android2JS.PrintBitmap(imgData);
                Android2JS.Print3Lines();
                Android2JS.CutPaper();
              }
            );
          }, Stimulsoft.Report.StiExportFormat.Html);
        } else {
          that.loading = false;
          await report.print();
        }
        
      });
    },
    // 封装请求  返回报表请求地址和body
    // 传入获取报表类型如   {type: "P01"},
    async requestUrlOrBody(data) {
      var requestData = {
        requestUrl: "",
        body: "",
      };
      let printData = await this.$fetchNo(
        this.Api.PrintReport + "/" + data.type
      );
      requestData.body = printData.data.body;
      requestData.requestUrl = printData.data.dataInterfaceUrl;
      let reportData = await this.$fetchNo(this.Api.ReportFormat, {
        reportId: printData.data.id,
      });
      if (reportData.data.items.length > 0) {
        let list = reportData.data.items.filter((item) => item.isDefault);
        if (list.length > 0) {
          requestData.body = list[0].body;
        }
      }
      return requestData;
    },
    // 请求报表单据打印
    axiosPrint(requestData, formData) {
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打印结账单
    async PrintReport(orderId) {
      //获取打印报表
      let data = {
        type: "P09",
      };
      let requestData = await this.requestUrlOrBody(data);
      let formData = {
        orderId: orderId,
        consumptionAreaId: localStorage.getItem("consumptionAreaId"),
      };
      var that = this;
      axios
        .post(
          requestData.requestUrl,
          {
            ...formData,
          },
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          that.dataSet(requestData.body, res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // 打印离场单
    async printDeparture(list) {
      console.log(list);
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002010");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // id:this.orderData.id,
          type: "P08",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        axios.post(requestData.requestUrl,{consumptionSubjectIdArray: list.map(item=>item.consumptionSubjectId)},
          {
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          }
        )
        .then(function (res) {
          // 打印多张离场单
          res.data.data.forEach((item, index) => {
            setTimeout(() => {
              that.dataSet(requestData.body, [item]);
              console.log(item.KeyNo); // 输出检查
            }, 3000 * (index)); // 每隔1.5秒执行一次
          });
        })
        .catch(function (error) {});

      }
    },
    // 打印鞋吧
    async printShoes(cashierIds) {
      let res = await this.$fetchNo(this.Api.getSettingCode + "/001002008");
      if (
        res &&
        res.data &&
        res.data.value &&
        !isNaN(res.data.value) &&
        parseInt(res.data.value) > 0
      ) {
        //获取打印报表
        let data = {
          // type: "P05",
        };
        let that = this;
        let requestData = await this.requestUrlOrBody(data);
        axios
          .get(requestData.requestUrl, {
            params: {
              consumptionSubjectIds: cashierIds.join(","),
            },
            headers: {
              access_token: localStorage.getItem("token"),
              x_req_application_id: 1,
              x_req_terminal: 1,
              x_req_store_id: localStorage.getItem("storeId"),
            },
          })
          .then(function (res) {
            that.dataSet(requestData.body, res.data.data);
          })
          .catch(function (error) {});
      }
    },
    // 自助机程序是否启用手牌回收箱
    getRecycler() {
      this.$fetchNo(this.Api.getSettingCode + "/001006005").then((res) => {
        this.isRecycler = (res.data.value == "1" && localStorage.getItem("type") == "自助结账") ? true : false;
        console.log('this.isRecycler==',this.isRecycler);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  padding: 240px 197px 0 197px;
  .code {
    width: 100%;
    margin-bottom: 60px;
    input {
      width: 100%;
      height: 140px;
      background: #f4f4f4;
      box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      font-size: 44px;
      text-indent: 32px;
      color: #1a1e22;
      font-weight: 700;
      caret-color: #155bd4;
    }
  }
  .keyboard {
    flex-wrap: wrap;
    margin-bottom: 60px;
    .number {
      width: 218px;
      height: 140px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      margin-right: 16px;
      font-size: 80px;
      font-weight: 700;
      color: #1a1e22;
      margin-bottom: 16px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    .clear {
      background: #d40000;
      font-size: 40px;
      color: #ffffff;
    }
    .affirm {
      background: #155bd4;
      font-size: 40px;
      color: #ffffff;
    }
  }
  .tips {
    font-size: 26px;
    line-height: 38px;
    color: #606876;
    .warning {
      height: 38px;
      margin-right: 10px;
    }
  }
}
</style>